import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { VehicleSearchParameters } from '../models';

@Component({
    selector: 'app-home',
    templateUrl: './home.component.html',
    styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {
    constructor(private router: Router) {}

    ngOnInit(): void {}

    /**
     * 
     * @param parameters 
     * @returns 
     */
    onInventorySearch(parameters: VehicleSearchParameters): Boolean {
        if(parameters) {
            this.router.navigate(['inventory'], { queryParams: parameters });
        }
        return false;
    }
}